<template>
	<div class="ContaBox">
		<div class="my-scrollbar" style="margin-bottom: .2rem;">
			<elSelectItem :options="optionTim" @change="_onChangeTime" :value="timValue"></elSelectItem>
			<elSelectItem style="margin-left: .2rem;"  :options="game_type" @change="_onChangeGame_type" :value="game_typeValue"></elSelectItem>
			<elSelectItem style="margin-left: .2rem;"  value="1" :options='[{text:"Detalhes da Subcategoria",value:"1"}]'></elSelectItem>
		</div>
		<div class="TBtGFT5INIot5sEUIm8w vantDataList ">
			<van-list v-if="shareInfo.list && shareInfo.list.length" v-model="loading" @load="onLoad"  :finished="finished" >
				<div class="yirWkOwey8HB15QLXqmz"  v-for="(item,index) in shareInfo.list" :key="index"  :title="item">
					<div class="E8vjOrxznOAKdRhXJRMX">
						<p class="ERUwi_8iD9f0T7LFIHMG"><span class="m_riTHvF1CQnegr_Ynkv">
								<div class="ioctHAlCRyfgD0kI26UX"><span class="NtkZoISf3gUcH6YB8lDO">{{item.type}}</span></div>
							</span><span>（{{item.desc}}）</span></p>
						<p><span>{{item.create_time}}</span></p>
					</div>
					<div class="E8vjOrxznOAKdRhXJRMX hm5kVDuxEiHavCx1SBCQ Dgl91M96ortC4Yxdh_xk">
						<p><span class="qC2KKT8QuStEpgYMFFeE U5W3_wqlgSns2oLo77ov">{{item.money}}</span></p>
						<p><span class="m_riTHvF1CQnegr_Ynkv h2r6I7i2DvwPfLIhDnGG">{{ $t('report._Saldo') }}</span></p>
					</div>
				</div>
				<!--  -->
				<p style="height: 1rem;"></p>
				
			</van-list>
			<van-empty image-size="2.1rem"  v-if="!shareInfo.list || !shareInfo.list.length" class="custom-image"
				:image="img_none_sj"
				:description="$t('description')" />
			<div class="waxH0FBvm5SKSHEIdmzj wg-fixed-no-desktop" style="z-index: 9999999999;">
				<div class="pBg296B2aLWiyWbbg5An">
					<span class="RjCX53sI34q23D2TwPWl">
						<span>{{ $t("report._DepósitoTotal") }}</span>
						<span class="VsQlwP6H52Vyv4bEeq1q HGHvRuWIBhpGiBhkEAHu">R$ {{shareInfo.recharge}}</span>
					</span>
					<span class="RjCX53sI34q23D2TwPWl">
						<span>{{ $t("report._TotaldeSaques") }}</span>
						<span class="kfrOXvCeC3B_UykAtxE_ HGHvRuWIBhpGiBhkEAHu">R$ {{shareInfo.withdraw}}</span>
					</span>
					<span class="RjCX53sI34q23D2TwPWl">
						<span> {{ $t("report._AcumuladoTotal") }}</span>
						<span class="cVihnbOVCk0AV6cIk86g">R${{shareInfo.get_money}}</span>
					</span>
				</div>
			</div>

		</div>


	</div>
</template>

<script>
	import datetimePicker from "@/components/searchFor/datetimePicker.vue";
	import searchFor from "@/components/searchFor/searchFor.vue";
	import indexCopy from "@/components/ant-select/indexCopy.vue";
	import elSelectItem from "./el-select-item.vue";
	import {
		ContaType,
		tim,
		timValue,
		ContaTypeValue,
		game_type,
		game_typeValue,
		Conta_Type,
		Conta_TypeValue
	} from "@/utils/tim.js"
	// allData
	import {
		getBalanceLog
	} from "@/apis/modules/share.js";
	export default {
		name: 'vip',
		components: {
			datetimePicker,
			searchFor,
			indexCopy,
			elSelectItem
		},

		data() {
			return {
				list: [],
				isTanzhans: false,
				loading: false,
				finished: false,
				shareInfo: {},
				shareTime: 'Ontem',
				ContaType: ContaType,
				timValue: timValue,
				optionTim: tim,
				game_type: Conta_Type,
				game_typeValue: Conta_TypeValue,
				ContaTypeValue: ContaTypeValue,
				active: 0,
				fromType:1,
				formData: {
					time: '',
				},
				 options: [{
				          value: '选项1',
				          label: '黄金糕'
				        }, {
				          value: '选项2',
				          label: '双皮奶'
				        }, {
				          value: '选项3',
				          label: '蚵仔煎'
				        }, {
				          value: '选项4',
				          label: '龙须面'
				        }, {
				          value: '选项5',
				          label: '北京烤鸭'
				        }],
				        value: ''
			}
		},
		mounted() {
			// console.log(tim)
			this.optionTim=tim.filter(item=>{
				return item.is!==false
			})
			this.getBalanceLog();
		},
		computed:{
			styleName(){
				// timValue
				return '1rem'
			}
		},
		methods: {
			_onChangeTime(e) {
				this.timValue=e[0]
				this.formData.time = e[0];
				this.shareInfo={}
				this.getBalanceLog();
			},
			_onChangeGame_type(e) {
				this.game_typeValue=e[0]
				this.formData.type = e[0];
				this.shareInfo={}
				this.getBalanceLog();
			},
			_search(e) {
				this.formData.search_id = e;
				this.shareInfo={}
				this.getBalanceLog();
			},
			onLoad() {
				if(this.finished){
					return false;
				}
				
				this.fromType=this.fromType+1;
				this.getBalanceLog()
			},
			getBalanceLog() {
				// vuex_token_head
				getBalanceLog({
					page:this.fromType,
					pid: this.$store.state.vuex_token_head.user_id,
					...this.formData
				}).then(({
					data
				}) => {
					if(JSON.stringify(data.list)=='[]' || data.list.length<50){
						this.finished = true;
					}
					if(data.list && this.shareInfo.list){
						data.list = [...this.shareInfo.list,...data.list]
					}
					this.loading = false;
					if(this.fromType==1){
						this.shareInfo = data;
					}else{
						this.shareInfo.list = [...this.shareInfo.list,...data.list];
					}
					
				})
			},
			tabIndexTime(index, time) {
				this.shareTime = time;
				this.active = index;
				this.shareMyData()
			},
		},
	}
</script>
<style>

</style>
<style scoped>
	.wg-fixed-no-desktop {
		left: initial;
	}

	.my-scrollbar {
		display: flex;
		padding-left: .2rem;
		overflow-x: scroll;
		/* overflow-x: scroll; */
		/* overflow-y: hidden */
	}

	.yirWkOwey8HB15QLXqmz {
		-ms-flex-align: center;
		-ms-flex-pack: justify;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		font-size: .2rem;
		justify-content: space-between;
		padding: .2rem !important
	}

	.ContaBox {
		padding-top: .2rem;
	}

	.nVAOZ5L2ZIX7FtNLSqAD {
		min-height: .4rem;
		position: relative;
		width: 100%
	}
	.ERUwi_8iD9f0T7LFIHMG {
	    display: -ms-flexbox;
	    display: flex;
	    margin-bottom: .1rem
	}
	
	.ERUwi_8iD9f0T7LFIHMG>span:first-child {
	    color: var(--theme-text-color-darken)
	}
	
	.ERUwi_8iD9f0T7LFIHMG>span {
	    margin: auto
	}
	
	.ERUwi_8iD9f0T7LFIHMG>span>div>span {
	    margin-right: 0!important
	}
	
	.ERUwi_8iD9f0T7LFIHMG>span:last-child {
	    margin-left: 0
	}
	.qC2KKT8QuStEpgYMFFeE {
	    color: var(--theme-text-color-darken)
	}
	.Dgl91M96ortC4Yxdh_xk>p:first-child {
	    margin-bottom: .1rem
	}
	.Bb2eRv4VS7avN4IlErAm>span {
		display: inline-block;
		vertical-align: middle;
		white-space: nowrap
	}

	.Bb2eRv4VS7avN4IlErAm>span:first-child {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap
	}

	.H9OepNjwbd602I30Pp_s {
		-ms-flex-pack: justify;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		font-size: .2rem;
		justify-content: space-between;
		padding: .2rem
	}

	.E8vjOrxznOAKdRhXJRMX {
		color: var(--theme-text-color-lighten)
	}

	.UXsnlJzbtvsfRVKRTLXu {
		color: var(--theme-text-color)
	}

	.H9OepNjwbd602I30Pp_s:not(:lang(zh_CN)) {
		padding: .18rem .1rem !important
	}

	.H9OepNjwbd602I30Pp_s .RmKh8CuQull6Xt98xhDv {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex
	}

	.H9OepNjwbd602I30Pp_s .RmKh8CuQull6Xt98xhDv .hUiIi4S5mS_zgUN7grJG {
		margin-right: .1rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 2.4rem
	}

	.copyIcon svg {
		color: var(--theme-primary-color);
		width: .22rem;
		height: .22rem;
	}

	.H9OepNjwbd602I30Pp_s .RmKh8CuQull6Xt98xhDv .hUiIi4S5mS_zgUN7grJG:not(:lang(zh_CN)) {
		width: 2.5rem
	}

	.YgwzG0T3H3PBaUc3A3_s {
		color: var(--theme-secondary-color-success)
	}

	.fABmUWsKxI0hI9mbzoYD {
		color: var(--theme-secondary-color-error);
	}


	.H9OepNjwbd602I30Pp_s .RmKh8CuQull6Xt98xhDv .bX48J467Rx96D17NO3u7 {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex: 1;
		flex: 1
	}

	.H9OepNjwbd602I30Pp_s .RmKh8CuQull6Xt98xhDv .bX48J467Rx96D17NO3u7>span {
		width: 50%
	}

	.H9OepNjwbd602I30Pp_s .RmKh8CuQull6Xt98xhDv .bX48J467Rx96D17NO3u7>span:first-child:not(:lang(zh_CN)) {
		display: inherit;
		width: 2.2rem
	}

	.pBg296B2aLWiyWbbg5An {
		-ms-flex-line-pack: space-evenly;
		align-content: space-evenly;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		font-size: .22rem;
		margin-bottom: 0;
		min-height: 1rem;
		padding: .05rem .2rem .1rem;
		width: 100%
	}

	.pBg296B2aLWiyWbbg5An .RjCX53sI34q23D2TwPWl {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		justify-content: center;
		margin: 0;
		width: 50%
	}

	.pBg296B2aLWiyWbbg5An .RjCX53sI34q23D2TwPWl>span {
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		display: -webkit-box;
		margin: 0;
		overflow: hidden;
		padding-right: .05rem;
		text-overflow: ellipsis;
		vertical-align: middle;
		width: 50%;
		word-break: break-all
	}

	.pBg296B2aLWiyWbbg5An {
		-ms-flex-line-pack: space-evenly;
		align-content: space-evenly;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		color: var(--theme-text-color);
		font-size: .22rem;
		font-weight: 200;
		margin-bottom: 0;
		min-height: 1rem;
		padding: .05rem .2rem .1rem;
		width: 100%
	}

	.RjCX53sI34q23D2TwPWl>span:last-child {
		padding-left: .2rem;
		padding-right: 0
	}

	.pBg296B2aLWiyWbbg5An .HGHvRuWIBhpGiBhkEAHu {
		color: var(--theme-text-color-darken);
	}

	.pBg296B2aLWiyWbbg5An .HNKlmlfGsE25ksqykrVs {
		color: var(--theme-secondary-color-error);
	}

	.pBg296B2aLWiyWbbg5An .VsQlwP6H52Vyv4bEeq1q {
		color: var(--theme-secondary-color-success);
	}

	.pBg296B2aLWiyWbbg5An .kfrOXvCeC3B_UykAtxE_ {
		color: var(--theme-secondary-color-error);
	}

	.pBg296B2aLWiyWbbg5An .cVihnbOVCk0AV6cIk86g {
		color: var(--theme-secondary-color-finance);
	}

	.H9OepNjwbd602I30Pp_s .RmKh8CuQull6Xt98xhDv .bX48J467Rx96D17NO3u7>span:nth-child(2):not(:lang(zh_CN)) {
		-ms-flex-pack: end;
		display: -ms-flexbox;
		display: flex;
		justify-content: flex-end;
		margin-left: .1rem;
		white-space: nowrap;
		width: 1.9rem
	}

	.H9OepNjwbd602I30Pp_s .wgK2LPtqwY6ykT9PTEFa {
		-ms-flex-align: center;
		word-wrap: break-word;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		margin-top: .12rem;
		word-break: keep-all
	}

	.H9OepNjwbd602I30Pp_s .wgK2LPtqwY6ykT9PTEFa .eFQ3axZpu8hrXwDmCzxd {
		margin-right: .1rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 2.4rem
	}

	.H9OepNjwbd602I30Pp_s .wgK2LPtqwY6ykT9PTEFa .eFQ3axZpu8hrXwDmCzxd:not(:lang(zh_CN)) {
		width: 2.5rem
	}

	.H9OepNjwbd602I30Pp_s .wgK2LPtqwY6ykT9PTEFa .aUJowNQhIpzaMd4FZGmN {
		-ms-flex-pack: justify;
		display: -ms-flexbox;
		display: flex;
		-ms-flex: 1;
		flex: 1;
		justify-content: space-between
	}

	.H9OepNjwbd602I30Pp_s .wgK2LPtqwY6ykT9PTEFa .aUJowNQhIpzaMd4FZGmN ._umF2Z34xk1F_GIsbss_ {
		max-width: 3.8rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap
	}

	.H9OepNjwbd602I30Pp_s .wgK2LPtqwY6ykT9PTEFa .aUJowNQhIpzaMd4FZGmN ._umF2Z34xk1F_GIsbss_:not(:lang(zh_CN)) {
		max-width: 3.86rem
	}

	.H9OepNjwbd602I30Pp_s .wgK2LPtqwY6ykT9PTEFa .aUJowNQhIpzaMd4FZGmN .zrPmGto4tAdnAA_BFB54 {
		display: -ms-flexbox;
		display: flex;
		font-size: .22rem
	}

	.H9OepNjwbd602I30Pp_s:not(:lang(zh_CN)) {
		padding: .18rem .1rem !important
	}


	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck {
		background-color: var(--theme-main-bg-color);
		border-top: .01rem solid var(--theme-color-line);
		bottom: 0;
		font-size: .2rem;
		line-height: .22rem;
		padding: .2rem .2rem 0;
		position: fixed;
		-webkit-transition: -webkit-transform .3s;
		transition: -webkit-transform .3s;
		transition: transform .3s;
		transition: transform .3s, -webkit-transform .3s;
		width: 100%
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck.kAaqfTc5guezIg4i8OHU {
		-webkit-transform: translateY(calc(100% - .3rem));
		transform: translateY(calc(100% - .3rem))
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .WKP0P703NvX8zzUuEJg9 {
		font-size: .2rem
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .WKP0P703NvX8zzUuEJg9 .van-col {
		-ms-flex-align: center;
		align-items: center;
		color: var(--theme-text-color-darken);
		display: -ms-flexbox;
		display: flex;
		margin-bottom: .15rem
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .WKP0P703NvX8zzUuEJg9 .van-col label {
		color: var(--theme-text-color-lighten);
		margin-right: .1rem;
		width: 50%
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .L7qtNTcvxkLyv8NTsUx8 {
		-ms-flex-align: self-start;
		-ms-flex-pack: center;
		align-items: self-start;
		background-color: var(--theme-main-bg-color);
		border-radius: .5rem .5rem 0 0;
		border-top: .02rem solid var(--theme-color-line);
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		color: var(--theme-primary-color);
		display: -ms-flexbox;
		display: flex;
		height: .35rem;
		justify-content: center;
		left: 50%;
		padding: .1rem;
		position: absolute;
		top: 0;
		-webkit-transform: translate(-50%, -98%);
		transform: translate(-50%, -98%);
		width: .7rem
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .L7qtNTcvxkLyv8NTsUx8 .dPPYvWfEoY8BG3Qg86dU {
		font-size: .2rem;
		width: .2rem;
		height: .2rem;
		-webkit-transition: -webkit-transform .3s;
		transition: -webkit-transform .3s;
		transition: transform .3s;
		transition: transform .3s, -webkit-transform .3s
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .L7qtNTcvxkLyv8NTsUx8 .dPPYvWfEoY8BG3Qg86dU.ED6sIMJuJEuPpvzsMw30 {
		-webkit-transform: rotate(-180deg);
		transform: rotate(-180deg)
	}

	.ZjZR4FiYzgfJQbE3_Etq {
		padding: .2rem;
	}

	.ZjZR4FiYzgfJQbE3_Etq {
		-ms-flex-align: center;
		-ms-flex-pack: start;
		align-items: center;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		display: -ms-flexbox;
		display: flex;
		justify-content: flex-start;
		padding-bottom: .2rem
	}

	.ifzwCQu8BPs2c24nZdzQ {
		margin-right: .2rem;
	}

	.vantDataList .van-list>div .isGreen {
		color: var(--theme-secondary-color-success)
	}

	.vantDataList .van-list>div .isRed {
		color: var(--theme-secondary-color-error)
	}



	.dG0FpGdkHU5MgpFCLljd {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		background-color: #c12929;
		border-radius: .04rem 0 .04rem 0;
		display: -ms-flexbox;
		display: flex;
		height: .23rem;
		justify-content: center;
		left: 0;
		line-height: 1;
		position: absolute;
		top: 0;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		width: .3rem
	}

	.dG0FpGdkHU5MgpFCLljd .TmCsEwuUw4tM7cplmM6W {
		-webkit-text-fill-color: transparent;
		-webkit-background-clip: text;
		background-clip: text;
		background-image: -webkit-gradient(linear, left top, left bottom, color-stop(-5%, #f7ea94), color-stop(58%, #e5b952), color-stop(114%, #ce9510));
		background-image: linear-gradient(180deg, #f7ea94 -5%, #e5b952 58%, #ce9510 114%);
		font-size: .16rem;
		font-weight: 700;
		line-height: 1
	}

	.vantDataList .van-list>div {
		color: var(--theme-text-color-darken);
		font-size: .2rem !important;
		position: relative
	}

	.Iu2XDEUxSXVTmZ2ClDl5 .s-s svg {
		font-size: .24rem;
		width: .24rem;
		height: .24rem;
		margin-left: .1rem;
		color: var(--theme-primary-color);
		position: relative;
	}

	.vantDataList .van-list>div label {
		color: var(--theme-text-color-lighten);
		margin-right: .1rem;
	}

	.vantDataList .van-list .van-col {
		margin-bottom: .05rem;
	}

	.dateTime {
		color: var(--theme-text-color-lighten);
		font-size: .16rem;
	}

	.greenColor {
		color: var(--theme-secondary-color-error) !important;
	}

	.redColor {
		color: var(--theme-secondary-color-success) !important;
	}

	.zhMFiMGv4SkVVrKaizPg .dateTime {
		font-size: .2rem !important
	}
</style>
